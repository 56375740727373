<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'EssAgrreements',
            query: { activeTab: 'My Agreements' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 250px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-4">
          Shared Agreement
        </h1>
      </div>

      <div class="w-7/12 mt-5">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div style="height: 100%" class="mt-20" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <ValidationObserver rules="required">
        <div class="px-3">
          <card class="p-5 mt-3">
            <p class="text-lg font-bold">Agreement Details</p>
            <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
              <div class="col-span-6">
                <div class="text-sm text-jet">Template</div>
                <c-select
                  label=""
                  placeholder="Select Template"
                  variant="w-full"
                  :options="templateOptions"
                  v-model="payload.templateId"
                  :rules="['required']"
                  :disabled="true"
                />
              </div>
              <div class="col-span-6">
                <div class="text-sm text-jet">Title</div>
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  class="label mt-2"
                  v-model="payload.title"
                  label="Title"
                  :rules="['required']"
                  :disabled="true"
                />
              </div>
            </div>

            <div
              class="w-full p-2 h-auto flex flex-col mt-5 cycle"
              v-if="payload.templateId !== ''"
            >
              <div class="w-11/12 flex cycle__text">CYCLE DETAILS</div>

              <card class="w-full fcard">
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="doccycle" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #490166">
                      {{ cycleTitle }}
                    </div>
                    <div class="w-full fcard__text">
                      {{ cycleDescription }}
                    </div>
                  </div>
                </div>
              </card>

              <div class="w-full h-auto flex flex-wrap">
                <card class="w-5/12 flex flex-grow mr-3 fcard">
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="calendarIcon" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #e99323">
                        Cycle Period
                      </div>
                      <div class="w-full fcard__text">
                        {{ $DATEFORMAT(new Date(cycleStartPeriod), "MMMM dd") }}
                        -
                        {{ $DATEFORMAT(new Date(cycleEndPeriod), "MMMM dd") }}
                      </div>
                    </div>
                  </div>
                </card>

                <card
                  class="w-5/12 flex flex-grow mb-3 fcard"
                  v-for="(cycles, index) in appraisal_cycles"
                  :key="index"
                >
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="reviewIcon" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #2176ff">
                        {{ cycles.name }}
                      </div>
                      <div class="w-full fcard__text">
                        {{
                          $DATEFORMAT(
                            new Date(cycles.appraisal_starts),
                            "MMMM dd"
                          )
                        }}
                        -
                        {{
                          $DATEFORMAT(
                            new Date(cycles.appraisal_ends),
                            "MMMM dd"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>

            <div
              class="w-full p-2 h-auto flex flex-col mt-5"
              style="border: 1px dashed #878e99; border-radius: 5px"
              v-else
            >
              <div class="w-11/12 h-6 p-1 flex cyc-text">
                PERFORMANCE DETAILS
              </div>
              <div class="w-11/12 h-6 p-1 mt-4 mb-3 inner-text">
                --Select a Performance Template to view Appraisal Performance
                Details here---
              </div>
            </div>
          </card>
        </div>

        <div class="px-3">
          <card class="p-5" style="margin-top: 4px">
            <div
              class="w-full flex flex-row"
              style="margin-top: 20px; height: 44px; flex-grow: 1"
            >
              <div class="w-9/12 h-auto mt-2">
                <h1 class="text-base font-bold">KPAs</h1>
              </div>

              <div class="h-full w-auto flex flex-row">
                <div
                  class="p-2 mx-3 bg-ghostWhite h-full flex"
                  style="border-radius: 5px"
                >
                  <span class="text-sm font-black flex flex-grow">
                    Total Allocated Weight:
                  </span>
                  <span class="text-sm text-desire ml-3">
                    {{ allocatedWeight }}%
                  </span>
                  <icon :icon-name="iconInfo" size="ksm" />
                </div>
              </div>
            </div>

            <div style="border-bottom: 1px solid #878e99; height: 1rem" />

            <div
              class="w-full h-auto my-6 flex flex-col"
              style="align-items: center"
              v-if="templateKPATypes.length === 0"
            >
              <div
                class="w-5/12 h-auto mb-4 mt-4 flex"
                style="justify-content: center"
              >
                <icon
                  :icon-name="illustration"
                  size="auto"
                  class-name="icon-Size mb-2"
                />
              </div>
              <div class="w-6/12 h-auto illu-text">
                You set organizational goals and objectives here. KPAs appear
                here when you select a template. You can edit selected template
                based on permissions assigned. Select a template to get started.
              </div>
            </div>

            <div class="flex flex-col w-full my-4" v-else>
              <!----- List of KPA's ---->

              <div class="w-full h-auto flex flex-col">
                <accordion
                  class="mx-3 mt-6"
                  v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                  :key="kpaNumber"
                  show-divider
                  @open="handleOpen($event, kpaTypes.id)"
                  :is-open="true"
                >
                  <template v-slot:title>
                    <span class="font-bold">{{ kpaTypes.name }}</span>
                  </template>
                  <div class="w-full">
                    <ValidationObserver>
                      <scroll-container height="1000px">
                        <Table
                          :headers="KPAHeader"
                          :items="kpaTypes.kpas"
                          class="w-full h-full mb-4 -mt-2 table"
                          aria-label="absence table"
                          :has-number="false"
                        >
                          <template v-slot:item="{ item }">
                            <div v-if="item.state">
                              <div v-if="item.data.state" class="p-2">
                                <div class="flex flex-col">
                                  <div
                                    class="cursor-pointer"
                                    v-if="item.data.isLocked"
                                    @click="lockKPA()"
                                  >
                                    <icon
                                      icon-name="icon-lock"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                  <div
                                    class="cursor-pointer"
                                    v-else
                                    @click="lockKPA()"
                                  >
                                    <icon
                                      icon-name="icon-unlock"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                  <div
                                    class="cursor-pointer"
                                    @click="
                                      deleteKPA(
                                        item.index,
                                        kpaTypes,
                                        item.data.kpaId
                                      )
                                    "
                                  >
                                    <icon
                                      icon-name="icon-trash"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-else-if="item.name">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter KPI--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-1 w-72"
                                  :disabled="item.data.isLocked"
                                  v-model="item.data.name"
                                  :rules="inputRules"
                                  label="KPI"
                                />
                              </div>
                            </div>

                            <div v-else-if="item.objective">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter Objective--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-2 w-72"
                                  :disabled="item.data.isLocked"
                                  v-model="item.data.objective"
                                  :rules="inputRules"
                                  label="Objective"
                                />
                              </div>
                            </div>

                            <div v-else-if="item.kpis" class="flex">
                              <div
                                class="label flex"
                                v-for="(n, rating) in item.data.kpis"
                                :key="rating"
                              >
                                <ValidationObserver>
                                  <c-text
                                    variant="w-full"
                                    :class="`mr-2 -mt-2 w-48`"
                                    :placeholder="`Criteria ${rating}`"
                                    :disabled="item.data.isLocked"
                                    v-model="item.data.kpis[rating].criteria"
                                    :rules="inputRules"
                                    :label="`Criteria ${rating} value`"
                                  />
                                </ValidationObserver>
                              </div>
                            </div>

                            <div v-else-if="item.target_date">
                              <div class="label w-72">
                                <div class="date-flex -mt-2">
                                  <datepicker
                                    placeholder="--Select Day & Month & Year--"
                                    style="width: 100%; outline: none"
                                    input-class="date-input"
                                    format="yyyy-MMMM-dd"
                                    v-model="item.data.target_date"
                                  />
                                </div>
                              </div>
                            </div>

                            <div v-else-if="item.weight">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter Weight--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-2 w-24"
                                  type="number"
                                  min="0"
                                  max="100"
                                  :rules="weightRules"
                                  v-model="item.data.weight"
                                  :disabled="item.data.isLocked"
                                  label="Weight"
                                />
                              </div>
                            </div>
                          </template>
                        </Table>
                      </scroll-container>
                    </ValidationObserver>
                  </div>
                </accordion>
              </div>
            </div>
          </card>
        </div>

        <div class="pl-3 flex mt-5" v-if="createAgreement">
          <div
            class="flex flex-grow"
            v-if="managerAgreement !== '' && employeeAgreement !== ''"
          >
            <Button :class="`bg-dynamicBackBtn text-white`" @click="mergeEdit">
              <span class="mr-6 ml-6">Merge and Edit</span>
            </Button>

            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center ml-4"
              width="12rem"
              @click="overRide"
              v-if="sharedId !== ''"
            >
              Override Existing
            </Button>
          </div>

          <div class="flex flex-grow" v-if="employeeAgreement === ''">
            <Button :class="`bg-dynamicBackBtn text-white`" @click="saveMerged">
              <span class="mr-6 ml-6">Save</span>
            </Button>
          </div>

          <div
            class="w-5/12 flex ml-1 mr-6 p-2 rounded-md bg-yellow-50 border border-yellow-500"
            v-if="managerAgreement !== '' && employeeAgreement !== ''"
          >
            <Icon
              class-name="text-carrotOrange mx-auto self-center"
              size="xs"
              icon-name="icon-info"
            />
            <span class="flex text-sm mx-3">
              You have a shared agreement with your line manager that hasn't
              been approved yet. Kindly merge the agreement or override it.
            </span>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    Icon,
    CText,
    Card,
    Button,
    CSelect,
    Accordion,
    Table,
    ScrollContainer,
    Datepicker,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreement",
          href: "Performance Agreement",
          id: "Agreement",
        },
        {
          disabled: false,
          text: "Shared Agreement",
          href: "Shared Agreement",
          id: "Shared Agreement",
        },
      ],
      illustration: "illustration",
      allocatedWeight: 0,
      templateKPATypes: [],
      agreementKpaTypes: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      inputRules: [
        "required",
        {
          name: "data",
          rule: () => this.validateEntries(),
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      loadingBar: true,
      loadingData: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      watchOthers: false,
      addOthersModal: false,
      previousGoalModal: false,
      userReportingTo: null,
      Ratings: [],
      ratingsIds: [],
      sharedId: "",
      templateOptions: [],
      validateKPA: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      appraisal_cycles: [],
      agreementId: "",
      kpaData: [],
      kpaDetails: [],
      kpaName: [],
      validateKpa: "",
      reportingTo: "",
      approvedSubmit: false,
      createAgreement: false,
      manager: false,
      managerAgreement: "",
      employeeAgreement: "",
      payload: {
        orgId: "",
        templateId: "",
        title: "",
        status: "draft",
        created_by: "",
        created_for: "",
        share_for_review: false,
        kpa_types: [],
      },
    };
  },

  methods: {
    lockKPA() {
      this.$toasted.error(
        "You do not have the privilege to lock or unlock an agreement",
        { duration: 5000 }
      );
    },

    validateWeight() {
      let sum = 0;

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      this.allocatedWeight = sum;

      return true;
    },

    validateEntries(t) {
      if (t !== "") {
        return true;
      }

      return false;
    },

    handleOpen(state) {
      if (state) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },

    deleteKPA(index, kpaTypes, id) {
      let sum = 0;
      if(kpaTypes.kpas.length > 1) {
        if (!this.approvedSubmit) {
        kpaTypes.kpas.splice(index, 1);

        this.$_deleteAgreementKPI(id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });

          this.templateKPATypes.forEach((kpaType) => {
            kpaType.kpas.map((kpi) => {
              sum += kpi.weight;
              return {};
            });
            return {};
          });

          this.allocatedWeight = sum;
        });
      }
      }else {
        this.$toasted.error('Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead', {duration: 5000});
      }
    },

    saveMerged() {
      if (this.allocatedWeight > 100) {
        this.$toasted.error(
          "Total allocated weight for all KPA's cannot be greater than 100%",
          { duration: 5000 }
        );
      } else {
        this.kpaData = [];

        this.templateKPATypes.forEach((kpaType) => {
          const newKPAs = kpaType.kpas.map(({ state, ...rest }) => ({
            ...rest,
          }));

          newKPAs.map((kpi) => {
            const kpiData = kpi;
            kpiData.target_date = this.formatDate(kpiData.target_date, "arrange");

            return {};
          });

          const validation = newKPAs.every(
            (item) => item.name || item.objective || item.weight
          );

          if (validation) {
            this.kpaData.push({
              kpa_types_id: kpaType.id,
              kpas: newKPAs,
            });
          } else {
            this.kpaData.push({
              kpa_types_id: kpaType.id,
              kpas: [],
            });
          }

          return {};
        });

        this.payload.kpa_types = this.kpaData;
        this.payload.share_for_review = true;
        this.payload.orgId = this.$orgId;
        this.payload.created_for = this.$AuthUser.id;
        this.payload.status = "active";

        this.$_createEmployeeAgreement(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.$router.push({
              name: "EssAgrreements",
              query: { activeTab: "My Agreements" },
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      }
    },

    overRide() {
      const payload = {
        sharedId: this.sharedId,
        agreementId: this.agreementId,
      };

      this.$_overideEmployeeAgreement(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.templateOptions = [];
          this.kpaDetails = [];
          this.kpaName = [];
          this.employeeAgreement = "";
          this.managerAgreement = "";
          this.templateKPATypes = [];
          this.loadingData = true;
          this.sharedId = "";
          this.agreementId = "";
          setTimeout(() => {
            this.getAgreements();
          }, 1000);
          this.getSharedAgreement();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    mergeEdit() {
      const payload = {
        agreementCreatedByManager: this.managerAgreement,
        agreementCreatedByEmployee: this.employeeAgreement,
      };

      this.$_mergeEditEmployeeAgreement(payload).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.templateOptions = [];
        this.kpaDetails = [];
        this.kpaName = [];
        this.employeeAgreement = "";
        this.managerAgreement = "";
        this.templateKPATypes = [];
        this.loadingData = true;
        setTimeout(() => {
          this.getAgreements();
        }, 1000);
      });
    },

    checkPayload() {
      this.validateKPA = [];

      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas;

        const validation = newKPAs.every(
          (item) => item.name && item.objective && item.weight
        );

        this.validateKPA.push(validation);
      });

      if (this.validateKPA.every((valid) => valid === true)) {
        this.submit("active");
      } else {
        this.validateEntries("");
      }
    },

    createNewKPA(kpa) {
      const arrayObj = kpa.kpas[0];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.criteria.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                criteria: this.getCriteriaData(),
                target_date: "",
                weight: "",
              });
            }
            return {};
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%",
            { duration: 5000 }
          );
        }
      }
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      const sortedKPIS = kpis.sort((a, b) => a.score.rating - b.score.rating);

      this.Ratings = sortedRatings;
      // eslint-disable-next-line no-param-reassign
      kpis = sortedKPIS;

      this.Ratings.map((rating, index) => {
        criteriaData.push({
          id: kpis[index].id,
          performanceRatingId: rating.id,
          criteria: kpis[index].criteria,
        });
        return {};
      });

      return criteriaData;
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    getAgreements() {
      this.$_getEmployeeAgreement(this.$AuthUser.id).then((response) => {
        this.loadingData = false;
        const myAgreements = response.data.EmployeePerformanceAgreements;

        myAgreements.filter((aggreement) => {
          if (aggreement.id === this.$route.params.id) {
            this.payload.templateId = aggreement.templateId;
            this.payload.created_by = aggreement.created_by;
            this.approvedSubmit = aggreement.approveAndSubmit;

            this.$_getOneTemplate(this.payload.templateId).then((result) => {
              this.cycleDescription =
                result.data.PerformanceTemplate.agreement_description;

              this.cycleTitle = result.data.PerformanceTemplate.agreement_title;

              this.payload.title = this.cycleTitle;

              this.templateOptions.push({
                id: aggreement.templateId,
                name: result.data.PerformanceTemplate.title,
              });
            });

            if (
              !aggreement.approveAndSubmit &&
              aggreement.created_by === this.$route.params.createdBy
            ) {
              this.managerAgreement = aggreement.id;
              this.payload.agreementId = aggreement.id;
              const agreementKPA = aggreement.employee_agreement_kpa;

              agreementKPA.forEach((kpa) => {
                this.kpaDetails.push(kpa);
              });
            }
          } else if (aggreement.id !== this.$route.params.id) {
            if (
              !aggreement.approveAndSubmit &&
              aggreement.created_by === this.$AuthUser.id
            ) {
              this.employeeAgreement = aggreement.id;
            }
          }
          return {};
        });

        setTimeout(() => {
          this.setAgreementData();
        }, 1000);
      });
    },

    setAgreementData() {
      let sum = 0;

      this.kpaDetails.map((detail) => {
        if (this.kpaName.includes(detail.kpa_type.id)) {
          this.templateKPATypes.filter((kpaType) => {
            if (kpaType.id === detail.kpa_type.id) {
              kpaType.kpas.push({
                state: true,
                isLocked: detail.isLocked,
                name: detail.name,
                objective: detail.objective,
                kpaId: detail.id,
                kpis: this.getCriteriaData(detail.kpis_employee),
                target_date: this.resetDate(detail.targetDate),
                weight: detail.weight,
              });
            }
            return {};
          });
        } else {
          this.templateKPATypes.push({
            id: detail.kpa_type.id,
            name: detail.kpa_type.name,
            kpas: [
              {
                state: true,
                isLocked: detail.isLocked,
                name: detail.name,
                objective: detail.objective,
                kpaId: detail.id,
                kpis: this.getCriteriaData(detail.kpis_employee),
                target_date: this.resetDate(detail.targetDate),
                weight: detail.weight,
              },
            ],
          });

          this.kpaName.push(detail.kpa_type.id);
        }
        return {};
      });

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += kpi.weight;
          return {};
        });
        return {};
      });

      this.allocatedWeight = sum;
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal.map((appraisalCycles) => {
          const startDate = `${
            appraisalCycles.cycle_start_date
          }/${new Date().getFullYear()}`;

          const endDate = `${
            appraisalCycles.cycle_end_date
          }/${new Date().getFullYear()}`;

          const [mm, dd, yyyy] = startDate.split("/");

          const [m, d, y] = endDate.split("/");

          this.cycleStartPeriod = new Date(`${dd}-${mm}-${yyyy}`).toISOString();

          this.cycleEndPeriod = new Date(`${d}-${m}-${y}`).toISOString();

          appraisalCycles.cycles.forEach((cycle) => {
            const startCycleDate = `${
              cycle.appraisal_starts
            }/${new Date().getFullYear()}`;

            const endCycleDate = `${
              cycle.appraisal_ends
            }/${new Date().getFullYear()}`;

            const [mmm, ddd, yyyyy] = startCycleDate.split("/");

            const [month, day, year] = endCycleDate.split("/");

            this.appraisal_cycles.push({
              name: cycle.name,
              appraisal_starts: new Date(
                `${ddd}-${mmm}-${yyyyy}`
              ).toISOString(),
              appraisal_ends: new Date(`${day}-${month}-${year}`).toISOString(),
            });
          });

          return {};
        });
      });
    },

    getPerformanceSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        response.data.PerformanceSettings.map((setting) => {
          const agreementEdit = setting.editDocument;
          this.getUser(agreementEdit);
          return {};
        });
      });
    },

    getUser(agreementEdit) {
      this.$_getManagerDirectReport(this.$AuthUser.id).then((result) => {
        this.loadingData = false;
        if (result.data.directReport.length === 0) {
          this.manager = false;
        } else {
          this.manager = true;
        }

        if (agreementEdit === "manager" && this.manager) {
          this.createAgreement = true;
        } else if (agreementEdit === "manager" && !this.manager) {
          this.createAgreement = false;
        } else if (agreementEdit === "employee" && this.manager) {
          this.createAgreement = false;
        } else if (agreementEdit === "employee" && !this.manager) {
          this.createAgreement = true;
        } else if (agreementEdit === "both") {
          this.createAgreement = true;
        }
      });
    },

    getSharedAgreement() {
      this.$_getEmployeeSharedAgreement(this.$AuthUser.id).then((response) => {
        this.sharedId = response.data.sharedAgreement.id;
        this.agreementId =
          response.data.sharedAgreement.employeePerformanceAgreementId;
      });
    },
  },

  mounted() {
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    setTimeout(() => {
      this.getAgreements();
    }, 1000);
    this.getAppraisalCycles();
    this.getPerformanceSettings();
    this.getSharedAgreement();
  },
};
</script>

<style>
.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: none;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>
